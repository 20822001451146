@charset "UTF-8";/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
.page-link {
  position:relative;
  display:block;
  color:#0d6efd;
  font-size: 12px;
  text-decoration:none;
  background-color:#fff;
  border:1px solid #dee2e6;
  transition:color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  cursor: pointer;
}
@media (prefers-reduced-motion:reduce) { .page-link{ transition:none } }
.page-link:hover { z-index:2;color:#0a58ca;background-color:#e9ecef;border-color:#dee2e6 }
.page-link:focus { z-index:3;color:#0a58ca;background-color:#e9ecef;outline:0;box-shadow:0 0 0 .1rem rgba(13,110,253,.25) }
.page-item:not(:first-child) 
.page-link { margin-left:-1px }
.page-item.active .page-link {
  z-index:3;
  color:#fff;
  background-color:#0d6efd;
  border-color:#0d6efd
}
.page-item.disabled .page-link { color:#6c757d;pointer-events:none;background-color:#fff;border-color:#dee2e6 }
.page-link { padding:.25rem .50rem }
.page-item:first-child .page-link { border-top-left-radius:.25rem;border-bottom-left-radius:.25rem }
.page-item:last-child .page-link { border-top-right-radius:.25rem;border-bottom-right-radius:.25rem }
.pagination-lg .page-link { padding:.75rem 1.5rem;font-size:1.25rem }
.pagination-lg .page-item:first-child .page-link { border-top-left-radius:.3rem;border-bottom-left-radius:.3rem }
.pagination-lg .page-item:last-child .page-link { border-top-right-radius:.3rem;border-bottom-right-radius:.3rem }
.pagination-sm .page-link { padding:.25rem .5rem;font-size:.875rem }
.pagination-sm .page-item:first-child .page-link { border-top-left-radius:.2rem;border-bottom-left-radius:.2rem }
.pagination-sm .page-item:last-child .page-link { border-top-right-radius:.2rem;border-bottom-right-radius:.2rem }
/*# sourceMappingURL=bootstrap.min.css.map */

#container {
  margin: .25rem;
}

.items {
  margin-bottom: .25rem;
}